import './footer.scss';

export default function Footer() {
    return (
        <footer>
            <p>
                0ep Protocol zkChat &copy; - 2024 - Built by&nbsp;<a href="https://oynozan.com/">oynozan</a>
            </p>
        </footer>
    )
}